import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

export default function PageFooter({ data }) {
    return (
        <div className="footer-wrapper">
            <div className="copyright">
                Copyright © {new Date().getFullYear()} Dita Basu.
            </div>
            <ul id="footer-menu">
                <StaticQuery
                    query={graphql` 
                        {
                            allContentfulPage(sort: {fields: [order]}) {
                                edges {
                                    node {
                                        name
                                        link
                                    }
                                }
                            }
                        }`
                    } 
                    render={ data => (data.allContentfulPage.edges.map(({ node, index }) => (
                        <li key={"b"+index}>
                            <Link to={`/${node.link}`}>{node.name}</Link>
                        </li>
                    )))}
                />
                
            </ul>
           
        </div>
    )
}