import React from "react"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { GatsbyImage, getImage} from "gatsby-plugin-image"

import { BLOCKS, MARKS } from "@contentful/rich-text-types"

function getRenderOptions(links, isBlogPost = false) {

    const assetBlockMap = new Map();
  
    for (const asset of links) {
      assetBlockMap.set(asset.contentful_id, asset);
    }
  
    return {
      renderMark: {
        [MARKS.CODE]: text => {
          return (
            <div className="video">
            <div dangerouslySetInnerHTML={{__html:text}}/>
            </div>

          );
        }
      },
      renderNode: {

  
        [BLOCKS.EMBEDDED_ASSET]: (node, next) => {
  
          const asset = assetBlockMap.get(
            node.data.target.sys.id,
          );
  
          const image = getImage(asset);

          return (

            <GatsbyImage className="photo" image={image} alt="Photo"/>
        
          );
        },
      }
    }
}

export default function RichText({ content }) {
    return (
        <div className="richtext">
            {documentToReactComponents(JSON.parse(content.maintext.raw), getRenderOptions(content.maintext.references))}
        </div>
    )
}