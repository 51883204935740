import React from "react"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'


export default function Book({ content }) {
    return (
        <div className="book">
            <img className="bookcover" id="bookcover" src={content.cover.fixed.src} alt="Book Cover" />
            <h2 id="booktitle">{content.name}</h2>
            <div><a href={content.url}><img src={content.amazonButton} alt="Buy on Amazon" /></a></div>
            {documentToReactComponents(JSON.parse(content.fulltext.raw))}
        </div>
    )
}