import React from "react"
import ShareButtons from "./sharebuttons"

export default function BlogPost({ content }) {
    const { previous, next } = content
    const url = typeof window !== 'undefined' ? window.location.href : '';
    return (
        <div className="blogpost">
            <h2 id="blogtitle">{content.node.title}</h2>
            <div className="byline">{content.node.publishDate} by Dita Basu</div>
            <br></br>
            <div className="blogbody" dangerouslySetInnerHTML={{__html:content.node.body.childMarkdownRemark.html}}></div>
            <br></br>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <a href={`/posts/${previous.id}`} rel="prev">
                ← {previous.title}
              </a>
            )}
          </li>
          <li>
            {next && (
              <a href={`/posts/${next.id}`} rel="next">
                {next.title} →
              </a>
            )}
          </li>
        </ul>
      </nav>
      <ShareButtons title={content.node.title} url={url}></ShareButtons>
      <div id="graphcomment"></div>
<script type="text/javascript" dangerouslySetInnerHTML={{__html:`

  /* - - - CONFIGURATION VARIABLES - - - */

  // make sure the id is yours
  window.gc_params = {
    graphcomment_id: 'DitaBasu',

    // if your website has a fixed header, indicate it's height in pixels
    fixed_header_height: 200,
  };

  /* - - - DON'T EDIT BELOW THIS LINE - - - */

  
  (function() {
    var gc = document.createElement('script'); gc.type = 'text/javascript'; gc.async = true;
    gc.src = 'https://graphcomment.com/js/integration.js?' + Date.now();
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(gc);
  })();`
  }}/>
        </div>
    )
}