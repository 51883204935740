import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

export default function NavBar({ data }) {
    return (
        <div className="nav-wrapper">
            <div className="main-navigation">
                <ul id="top-menu">
                    <StaticQuery
                        query={graphql` 
                            {
                                allContentfulPage(sort: {fields: [order]}) {
                                    edges {
                                        node {
                                            name
                                            link
                                        }
                                    }
                                }
                            }`
                        } 
                        render={ data => (data.allContentfulPage.edges.map(({ node, index }) => (
                            <li key={"f" + index}>
                                <Link to={`/${node.link}`}>{node.name}</Link>
                            </li>
                        )))}
                    />
                    
                </ul>
            </div>
        </div>
    )
}
