import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

export default function BookTeaser({ data }) {
    return (
            <StaticQuery
                query={graphql` 
                    {
                        contentfulBook {
                            name
                            teaser {
                                raw
                            }
                            cover {
                                fixed(width: 200) {
                                src
                                }
                            }
                            url
                            link
                        }
                        contentfulAsset(title: {eq: "AmazonButton"}) {
                            fixed(width: 149) {
                                src
                            }
                        }
                    }`
                } 
                render={ data => (
                    <div className="bookteaser">
                        <Link to={`/books/${data.contentfulBook.link}`}>
                            <img className="bookcover" src={data.contentfulBook.cover.fixed.src} alt="Book Cover" />
                            <h3>{data.contentfulBook.name}</h3>
                        </Link>
                        {documentToReactComponents(JSON.parse(data.contentfulBook.teaser.raw))}
                        <div><a href={data.contentfulBook.url}><img src={data.contentfulAsset.fixed.src} alt="Buy on Amazon" /></a></div>
                        <Link to={`/books/${data.contentfulBook.link}`} className="readmore">Read More</Link>
                    </div>
                
                )}
            />
    )
}