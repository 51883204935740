import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

export default function RecentPosts({ data }) {
    return (
        <div className="recentposts" >
            <h3>Recent Posts</h3>
            <StaticQuery
                query={graphql` 
                    {
                        allContentfulBlogPost(sort: {fields: publishDate, order: DESC}, limit: 5) {
                            edges{
                                node{
                                    id
                                    title
                                }
                            }
                        }
                    }`
                } 
                render={ data => (data.allContentfulBlogPost.edges.map(({ node, index }) => (
                    <div className="recentpost">
                        <a href={`/posts/${node.id}`}>
                        {node.title}
                        </a>
                    </div>
                )))}
            />
        </div>
    )
}