import React from "react"
import { StaticQuery, graphql } from "gatsby"

export default function Blog({ data }) {
    return (
        <div className="blog">
            <h3>Dita's Blog</h3>
            <StaticQuery
                query={graphql` 
                    {
                        allContentfulBlogPost (sort: {fields: publishDate, order: DESC}){
                            edges{
                                node{
                                    id
                                    description {
                                        childMarkdownRemark {
                                          html
                                        }
                                    }
                                    title
                                    publishDate(formatString: "MMMM Do, YYYY")
                                }
                            }
                        }
                    }`
                } 
                render={ data => (data.allContentfulBlogPost.edges.map(({ node, index }) => (
                    <div className="blogitem">
                        <a href={`/posts/${node.id}`}>
                            <div className="blogtitle">{node.title}</div>
                        </a>
                        <div className="byline">{node.publishDate} by Dita Basu</div>
                        <br></br>
                        <div dangerouslySetInnerHTML={{__html:node.description.childMarkdownRemark.html}}></div>
                        <br></br>
                        <a href={`/posts/${node.id}`} className="readmore">Read More</a>
                    </div>
                )))}
            />
        </div>
    )
}