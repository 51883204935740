import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faGoodreads, faAmazon } from '@fortawesome/free-brands-svg-icons'


export default function SocialButtons() {
    return (
        <div className="socialbuttons">
					<span className="follow-me-text">Follow Me:</span>
                    <div className="social-icons">
                        <a href="https://www.facebook.com/Anindita-Basu-119253158747215/" className="faicon" target="_blank">
                            <FontAwesomeIcon icon={faFacebookSquare}></FontAwesomeIcon>
                        </a>
                        <a href="https://www.goodreads.com/book/show/45888189-shadow-birds" className="faicon" target="_blank">
                            <FontAwesomeIcon icon={faGoodreads}></FontAwesomeIcon>
                        </a>
                        <a href="https://www.amazon.com/kindle-dbs/entity/author/B07SHJYVL1?_encoding=UTF8&node=283155&offset=0&pageSize=12&searchAlias=stripbooks&sort=author-sidecar-rank&page=1&langFilter=default#formatSelectorHeader" className="faicon" target="_blank">
                            <FontAwesomeIcon icon={faAmazon}></FontAwesomeIcon>
                        </a>
                    </div>
        </div>
    )
}