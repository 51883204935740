import React from "react"
import PageHeader from "./header.js"
import NavBar from "./navbar.js"
import PageFooter from "./pagefooter.js"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import PhotoSocial from './photosocial.js'
import BookTeaser from './bookteaser.js'
import Book from "./book.js"
import BookList from "./booklist.js"
import RichText from "./richtext.js"
import BlogTeaser from './blogteaser.js'
import BlogPost from "./blogpost.js"
import Blog from "./blog.js"
import Contact from "./contact.js"
import RecentPosts from "./recentposts.js"
import BookAd from "./bookad.js"

import "./css.css"
import "@fontsource/redressed"
import "@fontsource/open-sans"


function renderSwitch(name, content){
    switch(name){
        case "home":
            return (
                <div id="main_wrapper">
                    <div id="pagecolumns">
                        <div id="maincontent">{documentToReactComponents(JSON.parse(content.maintext.raw))}</div> 
                        <div id="rightcolumn"><PhotoSocial></PhotoSocial></div>
                    </div>
                    <div id="secondrow">
                        <BookTeaser></BookTeaser>
                        <BlogTeaser></BlogTeaser>
                    </div>
                </div>
            );
        case "bookdetail":
            return (
                <div id="main_wrapper">
                    <div id="pagecolumns">
                        <div id="leftcolumn">
                            <PhotoSocial></PhotoSocial>
                            <RecentPosts></RecentPosts>
                        </div>
                        <div id="maincontent">
                            <Book content={content}></Book>
                        </div>
                    </div>
                </div>
            )
        case "blogpost":
            return (
                <div id="main_wrapper">
                    <div id="pagecolumns">
                        <div id="leftcolumn">
                            <RecentPosts></RecentPosts>
                            <PhotoSocial></PhotoSocial>
                            <BookAd></BookAd>
                        </div>
                        <div id="maincontent">
                        <BlogPost content={content}></BlogPost> 
                        </div> 
                    </div>
                </div>
            )
        case "blog":
            return (
                <div id="main_wrapper">
                    <div id="pagecolumns">
                        <div id="leftcolumn">
                            <RecentPosts></RecentPosts>
                            <PhotoSocial></PhotoSocial>
                            <BookAd></BookAd>
                        </div>
                        <div id="maincontent"><Blog></Blog></div> 
                    </div>
                </div>
            )
        case "books":
            return (
                <div id="main_wrapper">
                <div id="pagecolumns">
                    <div id="leftcolumn">
                        <PhotoSocial></PhotoSocial>
                        <RecentPosts></RecentPosts>
                        
                    </div>
                    <div id="maincontent"><BookList></BookList></div>
                </div>
            </div>
            )
        case "author":
            return (
            <div id="main_wrapper">
                <div id="pagecolumns">
                    <div id="leftcolumn">
                        <PhotoSocial></PhotoSocial>
                        <BookAd></BookAd>
                        <RecentPosts></RecentPosts>
                    </div>
                    <div id="maincontent"><RichText content={content}/></div> 
                </div>
            </div>   
            )
        case "contact":
            return (
                <div id="main_wrapper">
                <div id="pagecolumns">
                    <div id="leftcolumn">
                        <PhotoSocial></PhotoSocial>
                        <BookAd></BookAd>
                        <RecentPosts></RecentPosts>
                    </div>
                    <div id="maincontent"><Contact></Contact></div>  
                </div>
            </div>
            )
        default:
            return (<div>Coming soon!</div>)
/*         
        case "contact":
            return ();
        case "blog":
            return (); */

    }

}

export default function Layout({ children, page, content }) {
  return (
    <div>
        <PageHeader>
            Dita Basu
        </PageHeader>
        <NavBar></NavBar>
        {renderSwitch(page, content)}
        <PageFooter></PageFooter>
      {children}
    </div>
  )
}