import React from "react"
import { StaticQuery, graphql } from "gatsby"

export default function BlogTeaser({ data }) {
    return (
            <StaticQuery
                query={graphql` 
                    {
                        allContentfulBlogPost(sort: {fields: publishDate, order: DESC}, limit: 1) {
                            edges{
                                node{
                                    id
                                    body {
                                        body
                                    }
                                description {
                                    childMarkdownRemark {
                                        html
                                    }
                                }
                                title
                                publishDate(formatString: "MMMM Do, YYYY")
                                }
                            }
                        }
                    }`
                } 
                render={ data => (data.allContentfulBlogPost.edges.map(({ node, index }) =>  ( 
                    <div className="blogteaser">
                        <h3>Blog</h3>
                        <a href={`/posts/${node.id}`}>
                            <div className="blogtitle">{node.title}</div>
                        </a>
                        <div className="byline">{node.publishDate} by Dita Basu</div>
                        <br></br>
                        <div dangerouslySetInnerHTML={{__html:node.description.childMarkdownRemark.html}}></div>
                        <br></br>
                        <a href={`/posts/${node.id}`} className="readmore">Read More</a>
                    </div>
                
                )))}
            />
    )
}