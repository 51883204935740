import React from 'react'

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon
} from 'react-share'

const ShareButtons = ({title, url, pic}) => {

    return(
        <div>
          <FacebookShareButton url={url} >
                <FacebookIcon  size={40} round={false}/>
         </FacebookShareButton>

          <TwitterShareButton url={url} title={title}>
                <TwitterIcon  size={40} round={false} />
          </TwitterShareButton>
        </div>
      )

}
export default ShareButtons