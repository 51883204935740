import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function BookAd({ data }) {
    return (
            <StaticQuery
                query={graphql` 
                    {
                        contentfulBook {
                            name
                            cover {
                                gatsbyImageData(width: 150)
                            }
                            link
                        }
                    }`
                } 
                render={ data => (
                    <div className="bookad">
                        <Link to={`/books/${data.contentfulBook.link}`}>
                            <GatsbyImage image={getImage(data.contentfulBook.cover.gatsbyImageData)} className="smallcover"></GatsbyImage>
                            <h4>{data.contentfulBook.name}</h4>
                        </Link>
                    </div>
                )}
            />
    )
}