import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

export default function BookList({ data }) {
    return (
        <div className="books">
            <StaticQuery
                query={graphql` 
                    {
                        allContentfulBook {
                            edges{
                                node{
                                    name
                                    teaser {
                                        raw
                                    }
                                    cover {
                                        fixed(width: 200) {
                                        src
                                        }
                                    }
                                    url
                                    link
                                }
                            }
                        }
                        contentfulAsset(title: {eq: "AmazonButton"}) {
                            fixed(width: 149) {
                                src
                            }
                        }
                    }`
                } 
                render={ data => (data.allContentfulBook.edges.map(({ node, index }) => (
                    <div className="booklistitem">
                        <Link to={`/books/${node.link}`}>
                            <img className="bookcover" src={node.cover.fixed.src} alt="Book Cover" />
                            <h3>{node.name}</h3>
                        </Link>
                        {documentToReactComponents(JSON.parse(node.teaser.raw))}
                        <div><a href={node.url}><img src={data.contentfulAsset.fixed.src} alt="Buy on Amazon" /></a></div>
                        <Link to={`/books/${node.link}`} className="readmore">Read More</Link>
                    </div>
                
                )))}
            />
            </div>
    )
}