import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import SocialButtons from "./socialbuttons"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

export default function PhotoSocial({ data }) {
    return (
        <div className="photosocial">
                <div id="ditaphoto">
                    <StaticQuery
                        query={graphql` 
                            {
                                contentfulAsset(title: {eq: "DitaPhoto"}) {
                                gatsbyImageData
                                description
                                }
                            }`
                        } 
                        render={ data => (
                        <>    
                            <GatsbyImage image={getImage(data.contentfulAsset.gatsbyImageData)} alt={data.contentfulAsset.description} />
                        </>
                        )}
                    />
                </div>
                <SocialButtons></SocialButtons>
        </div>
    )
}